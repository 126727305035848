import React from 'react';
import ReactDOM from 'react-dom/client';
import { unstable_HistoryRouter as HistoryRouter } from 'react-router-dom';
import { createBrowserHistory } from 'history';

import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

const history = createBrowserHistory({ window });

window.dataLayer = window.dataLayer || [];
function gtag() {
  if (window.location.hostname === 'localhost') {
    return;
  }
  window.dataLayer.push(arguments);
}

if (window.location.hostname !== 'localhost') {
  gtag('js', new Date());
  gtag('config', 'G-LJ9GYJG6M7');
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <HistoryRouter history={history}>
      <App />
    </HistoryRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
