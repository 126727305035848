import { useEffect, useState } from 'react';
import {
  Routes,
  Route,
  useNavigate,
  // useLocation,
  // useSearchParams,
  // NavLink,
} from 'react-router-dom';

import './App.css';
import { config } from './config';

const inpostCost = 16.99;
const products = [
  {
    id: 5,
    name: 'Poduszka Pieróg',
    desc: `Poduszka pierożek: welur w kolorze ecru, wypełnienie antyalergiczne, szerokość 30cm.
Dodaj odrobinę humoru i ciepła do swojego wnętrza. Spraw sobie lub bliskim wyjątkowy prezent!
Kup online lub skontaktuj się i odbierz osobiście podczas wycieczki.`,
    price: 70,
    images: [
      {
        thumbnail: '/IMG_2376.jpg',
        full: '/IMG_2376.jpg',
      },
      {
        thumbnail: '/IMG_2377.jpg',
        full: '/IMG_2377.jpg',
      },
      {
        thumbnail: '/IMG_1951.jpg',
        full: '/IMG_1951.jpg',
      },
    ],
  },
  {
    id: 6,
    name: 'Poduszka Obwarzanek',
    desc: `Poduszka obwarzanek: welur w kolorze beżowym, wypełnienie antyalergiczne, haft, średnica 30cm.
Uroczy obwarzanek z makiem to idealna pamiątka z Krakowa! Nie można go zjeść, ale zostanie z Tobą na zawsze 🙂
Kup online lub skontaktuj się i odbierz osobiście podczas wycieczki.`,
    price: 70,
    images: [
      {
        thumbnail: '/IMG_2382.jpg',
        full: '/IMG_2382.jpg',
      },
      {
        thumbnail: '/IMG_2381.jpg',
        full: '/IMG_2381.jpg',
      },
    ],
  },
  {
    id: 1,
    name: 'Świeca Smok Wawelski',
    desc: `Świeca w kształcie smoka wawelskiego. Unikalna pamiątka z Krakowa! Ręcznie wykonana z wosku sojowego w 100%, bez sztucznych dodatków.
Zapach: grzaniec`,
    price: 29.99,
    // image: '/dragon1.jpg',
    images: [
      {
        thumbnail: '/dragon2x.jpg',
        full: '/dragon2x_full.jpg',
      },
      {
        thumbnail: '/dragon1x.jpg',
        full: '/dragon1x_full.jpg',
      },
      {
        thumbnail: '/dragon2.jpg',
        full: '/dragon2_full.jpg',
      },

      // {
      //   thumbnail: '/dragon3.jpg',
      //   full: '/dragon3_full.jpg',
      // },
    ],
  },
  {
    id: 2,
    name: 'Voucher na zwiedzanie Krakowa',
    desc: `Zrób prezent swoim bliskim i podaruj im voucher na zwiedzanie Krakowa. Voucher można wykorzystać na dowolną trasę w dowolnym terminie.`,
    desc2: `Zrób prezent swoim bliskim i podaruj im voucher na zwiedzanie Krakowa. Voucher można wykorzystać na dowolną trasę w dowolnym terminie. Wystarczy, że podasz nam imię i nazwisko osoby, której chcesz podarować voucher. My wyślemy Ci voucher w formie pliku PDF, który możesz wydrukować lub przesłać mailem. Voucher jest ważny przez 12 miesięcy od daty zakupu.`,
    images: [
      {
        thumbnail: '/img/voucher_400.jpg',
        className: 'product-image-small',
      },
    ],
    link: 'https://zwiedzaniekrakowa.com',
  },

  {
    id: 3,
    name: 'Kartka Kraków Rynek Główny',
    desc: ``,
    price: 12,
    // image: '/dragon1.jpg',
    images: [
      {
        thumbnail: '/card2.jpg',
        full: '/card2_full.jpg',
      },

      // {
      //   thumbnail: '/dragon3.jpg',
      //   full: '/dragon3_full.jpg',
      // },
    ],
  },

  {
    id: 4,
    name: 'Kartka Kraków Kościół Mariacki',
    desc: ``,
    price: 12,
    // image: '/dragon1.jpg',
    images: [
      {
        thumbnail: '/card1.jpg',
        full: '/card1_full.jpg',
      },

      // {
      //   thumbnail: '/dragon3.jpg',
      //   full: '/dragon3_full.jpg',
      // },
    ],
  },

  // {
  //   id: 3,
  //   name: 'Smocze oko 3',
  //   desc: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit.`,
  //   price: 29.99,
  //   image: '/im1.png',
  // },
];

// const Map = ({ onChange, isVisible }) => {
//   useEffect(() => {
//     if (window.isInited) return;
//     console.log('init 1');
//     // window.easyPackAsyncInit = function () {
//     window.easyPack.init({
//       mapType: 'osm',
//       searchType: 'osm',
//     });
//     window.isInited = 1;
//     var map = window.easyPack.mapWidget('easypack-map', onChange);
//     //  };
//   }, []);

//   return (
//     <div className={isVisible ? 'inpost-map ' : 'inpost-map inpost-map-hidden'}>
//       <div id='easypack-map' />
//     </div>
//   );
// };

const send = async ({ body }) => {
  window.gtag?.('event', 'purchase', {
    event_category: 'engagement',
    event_label: 'purchase',
    value: 1,
    currency: 'PLN',
  });

  window.setIsLoading(true);
  document.getElementById('inpost-map-container').style.left = '-5000px';
  window.scrollTo(0, 0);

  const res = await fetch(`${config.appApiUrl}/order`, {
    method: 'POST',
    body: JSON.stringify(body),
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
    },
  });
  const data = await res.json();
  if (data.redirectUri) {
    window.location = data.redirectUri;
  }
};

const App = () => {
  const navigate = useNavigate();
  const [items, setItems] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  window.setIsLoading = setIsLoading;

  if (isLoading) {
    return <div className='loading'>Trwa ładowanie PayU...</div>;
  }

  return (
    <div className='app'>
      <div className='header'>
        <div
          className='logo'
          onClick={() => {
            navigate('/');
            window.scrollTo(0, 0);
          }}
        >
          KrakowGuideShop
        </div>
        <div className='contact-info'>
          <div className=''>+48 501 962 037</div>
          <div className=''>kontakt@zwiedzaniekrakowa.com</div>
        </div>
      </div>
      <Routes>
        <>
          <Route
            path='/'
            element={
              <AppMain
                items={items}
                setItems={setItems}
              />
            }
          />
          <Route
            path='/thanks'
            element={
              <div className='info-confirmation'>
                <p>Dziękujemy za wpłatę :)</p>
                <p>
                  O wysyłce zamówienia zostaniesz poinformowany na podany adres
                  email.
                </p>
                <p>W razie pytań prosimy o kontakt.</p>
              </div>
            }
          />
          <Route
            path='/chart'
            element={
              <Chart
                items={items}
                setItems={setItems}
              />
            }
          />
        </>
      </Routes>

      <div className='footer'>
        <a
          href='/regulamin.html'
          target='_blank'
          className='a-basic'
        >
          Regulamin
        </a>
        <a
          href='/polityka_prywatnosci.html'
          target='_blank'
          className='a-basic'
        >
          Polityka prywatności
        </a>
        <a
          href='/zwroty.html'
          target='_blank'
          className='a-basic'
        >
          Zwroty
        </a>

        <a
          href='https://zwiedzaniekrakowa.com'
          target='_blank'
          className='a-basic'
        >
          Przewodnik Kraków
        </a>

        <a
          href='https://www.instagram.com/krakow_guide/'
          target='_blank'
          className='a-basic'
        >
          Instagram @krakow_guide
          {/* <img src='/img/inst.png' /> */}
        </a>
      </div>
      <div className='copyright'>Copyright © 2023 KrakowGuideShop</div>
    </div>
  );
};

const Chart = ({ items, setItems }) => {
  const removeItem = (item) => {
    setItems(items.filter((i) => i.id !== item.id));
  };

  const inpostCostTotal =
    Math.ceil(
      items.reduce((acc, item) => {
        return acc + inpostCost * item.quantity;
      }, 0) * 100
    ) / 100;
  const costTotal =
    items.reduce((acc, item) => {
      return acc + item.price * item.quantity;
    }, 0) + inpostCostTotal;
  const costTotalRoundedUp = Math.ceil(costTotal * 100) / 100;

  const [data, setData] = useState({
    name: '',
    email: '',
    phone: '',
    inpost: null,
  });

  const handleSend = () => {
    const body = {
      ...data,
      items: items.map((item) => ({
        id: item.id,
        quantity: item.quantity,
        name: item.name,
      })),
    };
    send({
      body,
    });
  };

  window.onChangeInpost = (e) => {
    console.log(e);
    setData((prev) => ({
      ...prev,
      inpost: e,
    }));
  };

  useEffect(() => {
    if (data?.inpost) {
      handleSend();
    }
  }, [data.inpost]);

  return (
    <div className='form'>
      <div className='line'>
        <h3>Zamówienie</h3>
      </div>

      <div className='line'>
        <h4>Produkty</h4>

        {items.length === 0 && (
          <div className='cart-empty'>Koszyk jest pusty</div>
        )}

        <div className='cart-products'>
          {items.map((item) => (
            <div
              className='product'
              key={item.id}
            >
              <div className='product-name'>{item.name}</div>
              <div className='product-price'>Cena {item.price} zł</div>
              {(items.length > 1 || item.quantity > 1) && (
                <div className='product-quantity'>{item.quantity} szt.</div>
              )}

              <div className='product-button'>
                <div
                  className='button-basic button-third'
                  onClick={() => {
                    removeItem(item);
                  }}
                >
                  X
                </div>
              </div>
            </div>
          ))}
          <div className='product'>
            <div className='product-name'>Koszt dostawy Inpost</div>
            <div className='product-price'>{inpostCostTotal} zł</div>
          </div>

          <div className='product cart-total'>
            <div className='product-name'>Do zapłaty</div>
            <div className='product-price'>{costTotalRoundedUp} zł</div>
          </div>
        </div>
      </div>
      <div className='line'>
        <h4>Twoje dane</h4>

        <label>Imię i nazwisko</label>
        <input
          type='text'
          name='full-name'
          onChange={(e) => {
            setData({
              ...data,
              name: e.target.value,
            });
          }}
          value={data.name}
        />
      </div>
      <div className='line'>
        <label>Email</label>
        <input
          type='text'
          name='email'
          onChange={(e) => {
            setData({
              ...data,
              email: e.target.value,
            });
          }}
          value={data.email}
        />
      </div>
      <div className='line'>
        <label>Numer telefonu</label>
        <input
          type='text'
          name='phone'
          onChange={(e) => {
            setData({
              ...data,
              phone: e.target.value,
            });
          }}
          value={data.phone}
        />
      </div>

      <div className='final-actions'>
        <button
          className='button-final'
          onClick={() => {
            document.getElementById('inpost-map-container').style.left = '0';
            window.scrollTo(0, 0);
          }}
        >
          Wybierz paczkomat InPost
        </button>
      </div>

      {/* <div className='line inpost'>
        <label
          className=''
          onClick={() => {
            document.getElementById('inpost-map-container').style.left = '0';
          }}
        >
          Paczkomat Inpost
        </label>
        <div>
          {data.inpost?.name && (
            <span>
              {data.inpost?.name}, {data.inpost?.address.line1},{' '}
              {data.inpost?.address.line2}{' '}
            </span>
          )}
        </div>

        {items.length > 0 && data.inpost && (
          <div className='final-actions'>
            <button
              className='button-final'
              onClick={handleSend}
            >
              Dalej
            </button>
          </div>
        )}
      </div> */}
    </div>
  );
};

const AppMain = ({ items, setItems }) => {
  const navigate = useNavigate();
  const addItem = (item) => {
    const exists = items.find((i) => i.id === item.id);
    let quantity = 1;
    if (exists) {
      quantity = exists.quantity + 1;
    }
    setItems([
      ...items.filter((i) => i.id !== item.id),
      {
        ...item,
        quantity,
      },
    ]);
  };

  const [currentImage, setCurrentImage] = useState({});

  return (
    <div className='products'>
      {products.map((product) => (
        <div
          className='product'
          key={product.id}
        >
          <div className={`product-image ${product.images[0].className}`}>
            <img
              src={
                currentImage[product.id]?.thumbnail ||
                product.images[0].thumbnail
              }
              alt={product.name}
            />
            {(currentImage[product.id]?.full || product.images[0].full) && (
              <a
                className='product-image-zoom'
                href={currentImage[product.id]?.full || product.images[0].full}
                target='_blank'
              >
                Powiększ
              </a>
            )}
          </div>
          {product.images?.length > 1 && (
            <div className='product-images'>
              {product.images?.map((image) => (
                <div
                  className={`product-image ${image.className}`}
                  key={image.full}
                >
                  <img
                    src={image.thumbnail}
                    alt={product.name}
                    onClick={() => {
                      setCurrentImage({
                        ...currentImage,
                        [product.id]: image,
                      });
                    }}
                  />
                </div>
              ))}
            </div>
          )}
          <div className='product-name'>{product.name}</div>
          {product.price && (
            <div className='product-price'>Cena {product.price} zł</div>
          )}
          {!product.price && <div className='product-price'>od 280 zł</div>}
          <div className='product-desc'>{product.desc}</div>
          <div className='product-button'>
            {items.find((i) => i.id === product.id) ? (
              <>
                <div className='product-quantity'>
                  {items.find((i) => i.id === product.id).quantity} w koszyku
                </div>
                {/* <button
                    className='button-basic button-secondary'
                    onClick={() => {
                      removeItem(product);
                    }}
                  >
                    Usuń z koszyka
                  </button> */}
                <button
                  className='button-basic button-secondary'
                  onClick={() => {
                    addItem(product);
                  }}
                >
                  Dodaj więcej
                </button>
                <button
                  className='button-basic'
                  onClick={() => {
                    navigate('/chart');
                    // need to scroll top
                    window.scrollTo(0, 0);
                  }}
                >
                  Idź do koszyka
                </button>
              </>
            ) : (
              <>
                {product.link ? (
                  <a
                    className='button-basic'
                    href={product.link}
                    target='_blank'
                  >
                    Więcej
                  </a>
                ) : (
                  <button
                    className='button-basic'
                    onClick={() => {
                      addItem(product);
                    }}
                  >
                    Dodaj do koszyka
                  </button>
                )}
              </>
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

export default App;
