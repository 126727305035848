const baseConfig = {};

const devConfig = {
  ...baseConfig,
  appUrl: 'http://localhost:3000',
  appApiUrl: 'http://localhost:3001',
  googleAnalyticsId: '',
};

const prodConfig = {
  ...baseConfig,
  appUrl: 'https://krakowguideshop.com',
  appApiUrl: 'https://api.krakowguideshop.com',
  googleAnalyticsId: 'G-TFLPJRFWW5',
};

const isLocalServe = window.location.href.includes('http://localhost:3000');

export const config = isLocalServe ? devConfig : prodConfig;

export default config;
